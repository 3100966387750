
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Cards from "@/components/base/common/Cards.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from 'vee-validate'
import Froala from "@/components/base/editor/Froala.vue";
import {BENEFIT_TYPE} from "@/core/config/Constant";
import ProductBenefitService from "@/core/services/ProductBenefitService";
import BenefitType from "@/views/product/BenefitType.vue";
import Row from "@/components/base/common/Row.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ProductBenefits",
  components: {
    Row,
    BenefitType,
    Froala, FormErrorMessage, Field, BaseSelect, BaseForm, BaseModal, Cards, QuickAction, Entities
  },
  props: {
    productId: {type: String},
  },
  setup(props) {
    const state = ref({
      id: '',
      name: 'Add Benefit',
      model: {
        benefitType: 'M',
        deductible: 0,
        coinsurance: 0,
        maximum: 0,
        details: '',
        requestProductId: props.productId
      },
      submitting: false,
    })
    const objectFilter = ref({productId: props.productId});
    const page = computed(() => store.state.ProductBenefitModule.page);
    const product = computed(()=>store.state.ProductModule.product);
    return {
      product,
      page,
      objectFilter,
      state,
      BENEFIT_TYPE,
      ...LoadFilterObjects(Actions.LOAD_PRODUCT_BENEFITS, objectFilter.value, []),
    }
  },
  methods: {
    onAdd() {
      this.state.id = ''
      this.state.name = 'Add Benefit';
      this.state.model = {
        benefitType: 'M',
        deductible: 0,
        coinsurance: 0,
        maximum: 0,
        details: '',
        requestProductId: this.productId
      }
      const modal = this.$refs.formBenefitRef as typeof BaseModal
      modal.showBaseModal();
    },
    hideModal() {
      const modal = this.$refs.formBenefitRef as typeof BaseModal
      modal.hideBaseModal();
    },
    onEdit(data) {
      this.state.id = data.id;
      this.state.name = 'Edit Benefit'
      this.state.model = {
        benefitType: data.benefitType,
        deductible: data.deductible,
        coinsurance: data.coinsurance,
        maximum: data.maximum,
        details: data.details,
        requestProductId: this.productId
      }
      const modal = this.$refs.formBenefitRef as typeof BaseModal
      modal.showBaseModal();
    },
    onSubmit() {
      this.state.submitting = true
      if (this.state.id) {
        ProductBenefitService.update(this.state.id, this.state.model).then(() => {
          this.paginationLoad()
          this.hideModal()
        }).finally(() => {
          this.state.submitting = false
        })
      } else {
        ProductBenefitService.create(this.state.model).then(() => {
          this.paginationLoad()
          this.hideModal()
        }).finally(() => {
          this.state.submitting = false
        })
      }
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ProductBenefitService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})
